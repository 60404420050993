<template>
    <div class="list-group-item" style="overflow: hidden;">
        <!-- style="display: -webkit-box !important;" -->
        <div class="row" v-if="activityItem.User != null || activityItem.Key != null">
            <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                    <!--  -->
                    <router-link
                        v-if="activityItem.Location && activityItem.Company"
                        :to="{
                            name: 'profile',
                            params: {
                                locationId: activityItem.Location.Id,
                                companyId: activityItem.Company.Id,
                                userId: activityItem.User.Id,
                            },
                        }"
                    >
                    <img
                        class="avatar-img rounded-circle"
                        :src="activityItem.User.SmallImageUrl"
                        v-if="activityItem.User.SmallImageUrl"
                    />
                    <!-- class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary" -->
                    <div
                        v-else
                    >
                        <!-- <i class="fi flaticon-key"></i> -->
                        <img :src="'/images/person_MyZesec_64dp.png'" class="avatar-img rounded-circle"/>
                    </div>
                    </router-link>
                    <router-link
                        v-else-if="activityItem.Company"
                        :to="{
                            name: 'profile',
                            params: {
                                locationId: null,
                                companyId: activityItem.Company.Id,
                                userId: activityItem.User.Id,
                            },
                        }"
                    >
                    <img
                        class="avatar-img rounded-circle"
                        :src="activityItem.User.SmallImageUrl"
                        v-if="activityItem.User.SmallImageUrl"
                    />
                    <!-- class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary" -->
                    <div
                        v-else
                    >
                        <!-- <i class="fi flaticon-key"></i> -->
                        <img :src="'/images/person_MyZesec_64dp.png'" class="avatar-img rounded-circle"/>
                    </div>
                    </router-link>
                    <router-link
                        v-else
                        :to="{
                            name: 'profile',
                            params: {
                                // locationId: activityItem.Location.Id,
                                // companyId: activityItem.Company.Id,
                                userId: activityItem.User.Id,
                            },
                        }"
                    >
                    <!-- class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary" -->
                    <img
                        class="avatar-img rounded-circle"
                        :src="activityItem.User.SmallImageUrl"
                        v-if="activityItem.User.SmallImageUrl"
                    />
                    <!-- class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary" -->
                    <div
                        v-else
                    >
                        <!-- <i class="fi flaticon-key"></i> -->
                        <img :src="'/images/person_MyZesec_64dp.png'" class="avatar-img rounded-circle"/>
                    </div></router-link>

                </div>
            </div>
            <div class="col ml-n2">
               <p class="small text-gray-700 mb-0">
                    <transformed-link :link="getMessage"></transformed-link>
                </p>
                <small class="text-muted">{{ getDate }} {{ getTime }}</small>
            </div>
        </div>
        <!-- / .row -->
    </div>
</template>
<script>
import {
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_KEY_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
    ROLE_COMPANY_OWNER,
} from "@/acl/roles";
import{
    ACTIVITY_TYPE_ASSIGNED_ROLE_CM
} from "@/handlers/const";

import activityHelpersMixin from "./mixins/activityHelpersMixin";
import activityDescriptionMixin from "./mixins/activityDescriptionMixin";
import { trim } from "core-js/internals/string-trim";

export default {
    mixins: [activityHelpersMixin, activityDescriptionMixin],
    props: ["activityItem"],
    computed: {
        getMessage() {
            let keyNameNew = ''
            if(this.activityItem.Key != null){
                keyNameNew = this.activityItem.Key;
                if(keyNameNew.Name != null && keyNameNew.Name.includes("Key of: ")){
                    keyNameNew.Name = keyNameNew.Name.replace("Key of: ","");
                }
            }

            const obj = (this.activityItem.Description == null) ? {} : JSON.parse(this.activityItem.Description);
            var rUserName = trim(obj.RecipientName ?? "")
            if (rUserName === "" && obj.RecipientPhoneNumber != null)
                rUserName = obj.RecipientPhoneNumber
            if (rUserName === "")
                rUserName = "n/a"
            switch (this.description.RoleName) {
                case ROLE_KEY_PERMANENT:
                    return this.$t("activity.key_invite_sent", {
                        senderName: this.userLink(this.activityItem.User),
                        recepientName: rUserName,
                        targetName: this.keyLink(keyNameNew),
                        locationName: this.locationLink(),
                        companyName: this.companyLink(),
                        phoneNumber: obj.RecipientPhoneNumber,
                    });
                case ROLE_KEY_TEMPORARY:
                    return this.$t("activity.key_invite_sent", {
                        senderName: this.userLink(this.activityItem.User),
                        recepientName: rUserName,
                        targetName: this.keyLink(keyNameNew),
                        locationName: this.locationLink(),
                        companyName: this.companyLink(),
                        phoneNumber: obj.RecipientPhoneNumber,
                    });
                case ROLE_COMPANY_MANAGER:
                    return this.$t("activity.cmanager_invite_sent", {
                        senderName: this.userLink(this.activityItem.User),
                        recepientName: rUserName,
                        targetName: this.companyLink(),
                    });
                case ROLE_LOCATION_MANAGER:
                    return this.$t("activity.lmanager_invite_sent", {
                        senderName: this.userLink(this.activityItem.User),
                        recepientName: rUserName,
                        targetName: this.locationLink(),
                        companyName: this.companyLink(),
                    });
                case ROLE_KEY_MANAGER:
                    return this.$t("activity.kmanager_invite_sent", {
                        senderName: this.userLink(this.activityItem.User),
                        recepientName: rUserName,
                        targetName: this.locationLink(),
                        companyName: this.companyLink(),
                    });
                case ROLE_DEVICE_INSTALLER:
                    return this.$t("activity.installer_invite_sent", {
                        senderName: this.userLink(this.activityItem.User),
                        recepientName: rUserName,
                        targetName: this.locationLink(),
                        companyName: this.companyLink(),
                    });
                case ROLE_COMPANY_OWNER:
                    return this.$t("activity.cowner_invite_sent", {
                        senderName: this.userLink(this.activityItem.User),
                        recepientName: rUserName,
                        targetName: this.companyLink(),
                    });
            }
            return this.activityItem.Name;
        },
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>
