var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-group-item",staticStyle:{"overflow":"hidden"}},[(_vm.activityItem.User != null || _vm.activityItem.Key != null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[(_vm.activityItem.Location && _vm.activityItem.Company)?_c('router-link',{attrs:{"to":{
                        name: 'profile',
                        params: {
                            locationId: _vm.activityItem.Location.Id,
                            companyId: _vm.activityItem.Company.Id,
                            userId: _vm.activityItem.User.Id,
                        },
                    }}},[(_vm.activityItem.User.SmallImageUrl)?_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":_vm.activityItem.User.SmallImageUrl}}):_c('div',[_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":'/images/person_MyZesec_64dp.png'}})])]):(_vm.activityItem.Company)?_c('router-link',{attrs:{"to":{
                        name: 'profile',
                        params: {
                            locationId: null,
                            companyId: _vm.activityItem.Company.Id,
                            userId: _vm.activityItem.User.Id,
                        },
                    }}},[(_vm.activityItem.User.SmallImageUrl)?_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":_vm.activityItem.User.SmallImageUrl}}):_c('div',[_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":'/images/person_MyZesec_64dp.png'}})])]):_c('router-link',{attrs:{"to":{
                        name: 'profile',
                        params: {
                            // locationId: activityItem.Location.Id,
                            // companyId: activityItem.Company.Id,
                            userId: _vm.activityItem.User.Id,
                        },
                    }}},[(_vm.activityItem.User.SmallImageUrl)?_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":_vm.activityItem.User.SmallImageUrl}}):_c('div',[_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":'/images/person_MyZesec_64dp.png'}})])])],1)]),_c('div',{staticClass:"col ml-n2"},[_c('p',{staticClass:"small text-gray-700 mb-0"},[_c('transformed-link',{attrs:{"link":_vm.getMessage}})],1),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getDate)+" "+_vm._s(_vm.getTime))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }