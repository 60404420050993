export default {
    data() {
        return {
            description: null,
        };
    },
    created() {
        this.description = JSON.parse(this.activityItem.Description);
    },
};
